@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'satoshi_lighter';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Light.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Light.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Light.ttf") format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'satoshi_normal';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Regular.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf") format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'satoshi_medium';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Medium.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf") format('truetype');
    font-weight: medium;
}

@font-face {
    font-family: 'satoshi_bold';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype');
    font-weight: bold;
}

.heading_1 {
    color: var(--Text-primary, #0A0A0A);
    font-family: "Public Sans", sans-serif; 
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 75%;
}

.heading_2 {
    color: #141414;
    font-family: "satoshi_bold";
    font-size: 2.25rem;
    font-style: normal;
    line-height: 120%;
}

.heading_3 {
    color: #0C0C0C;
    font-family: "Lexend" sans-serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.heading_4 {
    color: var(--CoolGray-100, #121619);
    font-family: "satoshi_bold";
    font-size: 1.2rem;
    font-style: normal;
    line-height: 135%;
}

.heading_5 {
    color: #000;
    font-family: "satoshi_medium";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.0125rem;
}

.paragraph_1 {
    color: var(--Text-primary, #0A0A0A);
    font-family: "Inter", serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
}

.paragraph_2 {
    color: #626364;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.paragraph_3 {
    color: #141414;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.label_1 {
    color: #141414;
    font-family: "satoshi_bold";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.label_2 {
    color:#303030;
    /* color: var(--Ndovu-Black, #0873B9); */
    font-family: "Inter", serif;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
}
.label_2:hover {
    color:#EA720A;
    
}

.label_3 {
    color: #333;
    font-family: "satoshi_medium";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.label_4 {
    color: var(--Ndovu-Black, #121212);
    font-family: "satoshi_medium";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
}

.regular_12 {
    color: #989797;
    text-align: right;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.semi_bold_14 {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.semi_bold_24 {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: "Inter";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}

.title_medium_m3 {
    color: var(--Schemes-On-Surface, #1D1B20);
    font-family: var(--Title-Medium-Font, Roboto);
    font-size: var(--Title-Medium-Size, 1rem);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Title-Medium-Line-Height, 1.5rem);
    letter-spacing: var(--Title-Medium-Tracking, 0.00938rem);
}

.profile_email {
    color: var(--Black, #000);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.chama_card_date {
    color: #141414;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.activity_text {
    color: var(--Text-primary, #212121);
    font-family: "DM Sans";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.invalid span {
    color: red;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.select_title {
    color: var(--Gray-700, #344054);
    font-family: "satoshi_medium";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.toolTip_header {
    color: var(--Ndovu-Black, #121212);
    font-family: "satoshi_bold";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.011rem;
}

.toolTip_text {
    color: var(--Neutral-90, #404040);
    font-family: "satoshi_medium";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.role_header {
    color: var(--Gray-900, #101828);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.role_text {
    color: var(--Gray-500, #667085);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.role_btn_text {
    color: var(--Black, #000);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.send_invite_text {
    color: #1D191B;
    font-family: "satoshi_medium";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
}

.settlement_empty_text {
    color: var(--light-type-medium, #64748B);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.activity_card_header {
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: "satoshi_bold";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
}

.activity_card_text {
    color: #FFF;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: "Inter";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}

.view_more_text {
    color: var(--Ndovu-Black, #121212);
    font-family: "satoshi_normal";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.modal_success_header {
    color: var(--Neutral-100, #0A0A0A);
    font-family: "Public Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.011rem;
}

.modal_success_text {
    color: var(--Neutral-90, #404040);
    font-family: "Open Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.graph_icon_text {
    color: #0D163A;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Plus Jakarta Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.member_info_text {
    color: var(--Black-Default, #000);
    font-family: "Public Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
}
.typography{
    color: var(--White, #FFF);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.typography2{
    color: #FFF;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.3rem */
}
.typography-footer{
color: rgba(255, 255, 255, 0.71);
font-family: "Public Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 1.3125rem; /* 150% */
letter-spacing: -0.00525rem;
}

.paragraph_paragraph_bb13 {
	color: var(--nea-primary-500, #f0a500);
	font-family: "DM Sans";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; 
}