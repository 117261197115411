@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

:root {
    /* Primary Colors */
    /* --primary-navy-blue: #1E3A8A; */
    --primary-navy-blue: #3F51F4;
    --primary-emerald-green: #2F855A;
    --primary-slate-grey: #4A5568;

    /* Secondary Colors */
    --secondary-gold: #D69E2E;
    --secondary-light-grey: #A0AEC0;
    --soft-grey: #E0E0E0;

    /* Neutral Colors */
    --neutral-white: #F9F9F9;

    /* Accent Colors */
    --accent-royal-blue: #3B82F6;
    --accent-soft-green: #68D391;
    --accent-warm-yellow: #F6E05E;

    --mainBlack: #10101E;
    --lightGold: #FFB600;
    --blu:#1677FF;
    --black1: #333,
}


body {
    background-color: var(--neutral-white);
    color: var(--primary-slate-grey); 
    }
 

sup {
    vertical-align: super !important;
}

.button-primary {
    background-color: var(--accent-royal-blue);
    color: var(--neutral-white);
}

.button-secondary {
    background-color: var(--secondary-gold);
    color: var(--neutral-white);
}

.success-message {
    color: var(--accent-soft-green);
}

.warning-message {
    color: var(--accent-warm-yellow);
}

.white_card {
    display: flex;
    padding: 1rem 2.5rem;
    align-self: stretch;
    border-radius: 1.25rem;
    background: #FFF;
}

@media (max-width: 600px) {
	.white_card {
        padding: .5rem;
	}
}

.account_card {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
    min-height: 5.3125rem;
    height: auto;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    border-radius: 0.75rem;
    border: 1px solid var(--Ndovu-Black, #121212);
    background: #FFF;
}

.account_card_alt {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
    min-height: 5.3125rem;
    height: auto;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    border-radius: 1.5rem;
    border: 1px solid var(--Tile-stroke, #E2E9EE);
    background: #FFF;
}

.modal_option_card {
    display: flex;
    min-width: 16.125rem;
    width: auto;
    padding: 3.75rem 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: 0.5rem;
    border: 1px solid var(--Text-Inverse-Tertiary, #F5F5F5);
    background: #FFF;
}

.modal_option_card:hover {
    border-radius: 0.5rem;
    border: 1.5px solid #FFCA76;
    background: #FFFDF9;
}

.modal_option_card_inner {
    width: fit-content;
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 62.5rem;
    background: #FFF8EC;
}

.modal_option_card:hover>.modal_option_card_inner {
    background: #FFE8C4;
}

.pointer{
    cursor: pointer;
    width: 100%;
}

.login-pic {
	background-image: url('./assets/img/img1.png');
	/* height: 100%; */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.outer-card-content{
    border-radius: 0.5rem;
    border: 1px solid var(--Tile-stroke, #E2E9EE);
    background: #FFF; 
}

